<template>
  <div v-if="!showError">
    <card class="p-5">
      <div class="flex justify-start items-center my-2">
        Permissions for ESS. You can override permissions set.
      </div>
    </card>
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else class="grid grid-cols-12 gap-y-20 mt-20">
      <div class="col-span-4">
        <div class="text-base text-darkPurple font-semibold">
          Employees can update profile info
        </div>
        <radio-button
          :options="updateInfo"
          col-span="col-span-5"
          :disabled="showError"
          class="m-4 text-darkPurple"
          row-gap="gap-y-1"
          v-model="ess.canUpdateProfileInfo"
        />
      </div>
      <div class="col-span-4">
        <div class="text-base text-darkPurple font-semibold">
          Employees can view payslip
        </div>
        <radio-button
          :options="createPayslip"
          col-span="col-span-5"
          :disabled="showError"
          class="m-4 text-darkPurple"
          row-gap="gap-y-1"
          v-model="ess.canViewPayslip"
        />
      </div>
      <div class="col-span-4">
        <div class="text-base text-darkPurple font-semibold">
          Employees can update bank info
        </div>
        <radio-button
          :options="updateBankInfo"
          col-span="col-span-5"
          :disabled="showError"
          class="m-4 text-darkPurple"
          row-gap="gap-y-1"
          v-model="ess.canUpdateBankInfo"
        />
      </div>
      <div class="col-span-4">
        <div class="text-base text-darkPurple font-semibold">
          Employees can update PFA
        </div>
        <radio-button
          :options="updatePfa"
          col-span="col-span-5"
          :disabled="showError"
          class="m-4 text-darkPurple"
          row-gap="gap-y-1"
          v-model="ess.canUpdatePFA"
        />
      </div>
    </div>
    <div v-if="!showError" class="flex mt-80">
      <Button
        @click="handleSubmit"
        :disabled="disableBtn"
        class="bg-dynamicBackBtn text-white my-6 w-40 flex"
      >
        Save changes
      </Button>
      <Button @click="handleReset()" class="buttons mt-6">
        Revert changes
      </Button>
    </div>
  </div>
  <error-page v-else />
</template>

<script>
export default {
  name: "Permission",
  components: {
    Card: () => import("@/components/Card"),
    RadioButton: () => import("@/components/RadioButton"),
    Button: () => import("@/components/Button"),
    ErrorPage: () => import("@/modules/Admin/error403")

  },
  data() {
    return {
      loading: true,
      initialResult: {},
      disableBtn: false,
      showError: false,
      ess: {
        canUpdateProfileInfo: null,
        canViewPayslip: null,
        canCreateBankInfo: null,
        canUpdateBankInfo: null,
        canSelectPFA: null,
        canUpdatePFA: null
      },
      updateInfo: [
        { name: "Yes", radioName: "updateInfo", value: true },
        { name: "No", radioName: "updateInfo", value: false }
      ],
      createPayslip: [
        { name: "Yes", radioName: "viewPayslip", value: true },
        { name: "No", radioName: "viewPayslip", value: false }
      ],
      updateBankInfo: [
        { name: "Yes", radioName: "updateBankInfo", value: true },
        { name: "No", radioName: "updateBankInfo", value: false }
      ],
      updatePfa: [
        { name: "Yes", radioName: "updatePfa", value: true },
        { name: "No", radioName: "updatePfa", value: false }
      ]
    };
  },

  watch: {},

  methods: {
    handleReset() {
      this.ess = this.initialResult;
    },
    async handleSubmit() {
      this.disableBtn = true;
      const { data } = await this.$_saveEssPermissions(this.ess);
      this.$toasted.success(`${data.message}`, { duration: 3000 });
      await this.$store.dispatch("employee/getEssPermissions");
      this.disableBtn = false;
    },
    async getPermission() {
      const { data } = await this.$_getEssPermissions();
      this.initialResult = { ...data.permissions };
      this.ess = data.permissions;
      this.loading = false;
    }
  },

  async mounted() {
    try {
      await this.$handlePrivilege("permissionHR", "editEssActions");
      this.showError = false
    } catch (error) {
      this.showError = true
      this.$toasted.error("You do not have permission to edit this tab", {
          duration: 5000
        });
    }
    this.getPermission();
  }
};
</script>

<style></style>
